
  import { defineComponent, ref, computed, getCurrentInstance } from "vue"
  import { SingleResourceDoc, ResourceObject } from '@/models/jsonapi'
  import { User } from '@/models/users'
  import AddressCard from "@/components/shared/AddressCard.vue";

  interface Props {}

  export default defineComponent({
    components: {
      AddressCard
    },
    props: {},
    setup(props: Props) {
      const root = getCurrentInstance().proxy
      const user = computed<ResourceObject<string, User>>(() => root.$store.state.users.currentUser?.data)
      const refreshData = () => {root.$store.dispatch('user/get_current_user')}
      return {
        user,
        refreshData
      }
    }
  })
